<template>
  <b-card class="p-2">
    <b-link @click="goBack">
      <feather-icon
        class="position-absolute text-primary"
        size="40"
        icon="ArrowLeftCircleIcon"
      />
    </b-link>
    <b-row>
      <b-col
        class="mb-4 d-flex justify-content-center align-items-center"
        cols="12"
      >
        <feather-icon
          icon="MonitorIcon"
          size="25"
          class="mr-2"
        />
        <h1>Informazioni Sala Riunioni</h1>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        v-if="meetingroomData.image_path"
        class="mb-2"
        cols="12"
      >
        <b-img
          :src="mediaUrl + meetingroomData.image_path"
          alt="Immagine Sala Riunioni"
          class="meetingroom_img"
        />
      </b-col>
      <b-col
        class="mb-2"
        cols="12"
        md="6"
        lg="4"
      >
        <p class="mb-1">
          ID Sala Riunioni:
        </p>
        <h3 class="mb-1">
          #{{ meetingroomData.id }}
        </h3>
      </b-col>
      <b-col
        class="mb-2"
        cols="12"
        md="6"
        lg="4"
      >
        <p class="mb-1">
          Nome Sala Riunioni:
        </p>
        <h3 class="mb-1">
          {{ meetingroomData.name }}
        </h3>
      </b-col>
      <b-col
        v-if="meetingroomData.max_persons"
        class="mb-2"
        cols="12"
        md="6"
        lg="4"
      >
        <p class="mb-1">
          N° Posti Massimo:
        </p>
        <h3 class="mb-1">
          {{ meetingroomData.max_persons }}
        </h3>
      </b-col>
      <b-col
        v-if="meetingroomData.description"
        class="mb-2"
        cols="12"
      >
        <p class="mb-1">
          Descrizione:
        </p>
        <h4 class="mb-1">
          {{ meetingroomData.description }}
        </h4>
      </b-col>
      <b-col
        v-if="meetingroomData.headquarter"
        class="mb-2"
        cols="12"
        md="6"
        lg="4"
      >
        <p class="mb-1">
          Sede di Appartenenza:
        </p>
        <h3 class="mb-1">
          <feather-icon
            icon="MapPinIcon"
            size="25"
            class="text-primary"
          />
          <b-link
            :to="{ name: 'apps-headquarters-view', params: { id: meetingroomData.headquarter.id } }"
            class="font-weight-bold text-nowrap"
          >
            #{{ meetingroomData.headquarter.id }} - {{ meetingroomData.headquarter.name }}
          </b-link>
        </h3>
      </b-col>
      <b-col
        v-if="meetingroomData.note"
        class="mb-2"
        cols="12"
      >
        <p class="mb-1">
          Note:
        </p>
        <h4 class="mb-1">
          {{ meetingroomData.note }}
        </h4>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        v-if="meetingroomData.features && meetingroomData.features.length > 0"
        class="mb-2"
        cols="12"
      >
        <p class="mb-1">
          Caratteristiche:
        </p>
        <b-badge
          v-for="feature in meetingroomData.features"
          :key="feature"
          class="mr-2"
          variant="primary"
        >
          #{{ feature }}
        </b-badge>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        class="text-right mt-2"
        cols="12"
      >
        <b-button
          :to="{ name: 'apps-meetingroomsreception-edit', params: { id: meetingroomData.id } }"
          variant="primary"
        >
          Modifica
        </b-button>
        <!-- <b-button
          @click="
            $bvModal.show('delete-modal')
            selectedMeetingroom = meetingroomData.id
          "
          variant="outline-danger"
          class="ml-1"
        >
          Elimina
        </b-button> -->
      </b-col>
    </b-row>
    <b-modal
      id="delete-modal"
      title="Cancella Sala Riunioni"
      footer-class="d-flex justify-content-end"
      body-class="p-0"
      size="sm"
      centered
    >
      <template #modal-header="{ close }">
        <h5 class="mb-0">
          Cancella Sala Riunioni
        </h5>
        <feather-icon
          icon="XIcon"
          size="18"
          @click="close()"
        />
      </template>
      <p class="m-2">
        Sei sicuro di voler cancellare definitivamente questa Sala Riunioni e i suoi dati correlati?
      </p>
      <template #modal-footer="{ ok, cancel }">
        <b-button
          size="sm"
          variant="danger"
          @click="cancel()"
        >
          Annulla
        </b-button>
        <b-button
          size="sm"
          variant="success"
          @click="
            ok()
            deleteMeetingroom(selectedMeetingroom)
          "
        >
          OK
        </b-button>
      </template>
    </b-modal>
  </b-card>
</template>

<script>
import {
  BCard, BButton, BRow, BCol, BBadge, BLink, BModal, BImg,
} from 'bootstrap-vue'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    BBadge,
    BLink,
    BModal,
    BImg,
  },
  props: {
    meetingroomData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      selectedMeetingroom: null,
    }
  },
  computed: {
    mediaUrl() {
      return process.env.VUE_APP_MEDIA_URL
    },
  },
  methods: {
    goBack() {
      this.$router.go(-1)
    },
    deleteMeetingroom(id) {
      store
        .dispatch('app-meetingrooms/deleteMeetingroom', { id })
        .then(() => {
          this.selectedMeetingroom = null
          this.$router.replace({ name: 'apps-meetingroomsreception-list' }).then(() => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Sala Riunioni #${id} eliminata con successo`,
                icon: 'MonitorIcon',
                variant: 'success',
              },
            })
          })
        })
        .catch(error => {
          if (error === 401) {
            localStorage.removeItem('userData')
            store.commit('user/updateUserData', null)
            window.location.reload(true)
          }
        })
    },
  },
}
</script>

<style>
.position-absolute {
  z-index: 1;
}
.meetingroom_img {
  width: 100%;
  max-height: 500px;
  object-position: center;
  object-fit: cover;
  border-radius: 5px;
}
</style>
